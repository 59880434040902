import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Grid from '../components/Grid';
import { $ } from 'jquery';

export default class Flows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Website Content",
            sublabel: "Content",
            breadcrumbs: [{ label: "Content", href: "/content" },
            { label: "Content", href: "/content" }, { label: "Content Management", href: "/flows" }],
            gridColumns: ["id", "name", "title", "image", "url"],
            endpoint: "content/",
            editLink: "/editcontent?id=",
            pageLinks: [{ label: "Create Content", link: "/createcontent" }]
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loading: true });
        this.setState({ loading: false });
    }

    render() {
        const { breadcrumbs, label, sublabel, loading, pageLinks } = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div className="content-wrap">
                    <main id="content" className="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <section className="widget">
                                    <header>
                                        <h5>
                                            <span className="fw-semi-bold">{sublabel}</span>
                                        </h5>
                                        <div className="widget-controls">
                                            {pageLinks.map(data => {
                                                return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                            })}
                                        </div>
                                    </header>
                                    <div className="widget-body">
                                        {(loading == true) && <Spinner bg="#fff" fill="#f00" />}
                                        <Grid data={this.state} />

                                    </div>
                                </section>
                            </div>
                        </div>
                        <Footer />
                    </main>
                </div>
                <div className="loader-wrap hiding hide">
                    <i className="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}