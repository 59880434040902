import logo from './logo.svg';
import './App.css';
import history from './history';
import Index from './pages/Index';
import Grid from './pages/Grid';
import CreateForm from './pages/CreateForm';
import Home from './pages/Home';

import Colors from './pages/Colors';
import CreateColors from './pages/CreateColors';
import EditColors from './pages/EditColors';

import Menu from './pages/Menu';
import CreateMenu from './pages/CreateMenu';
import EditMenu from './pages/EditMenu';

import Categories from './pages/Category';
import CreateCategories from './pages/CreateCategory';
import EditCategories from './pages/EditCategory';

import SubCategories from './pages/SubCategory';
import CreateSubCategories from './pages/CreateSubCategory';
import EditSubCategories from './pages/EditSubCategory';

import Fabric from './pages/Fabric';
import CreateFabric from './pages/CreateFabric';
import EditFabric from './pages/EditFabric';

import Concepts from './pages/Concepts';
import CreateConcepts from './pages/CreateConcepts';
import EditConcepts from './pages/EditConcepts';

import Flows from './pages/Flows';
import CreateFlows from './pages/CreateFlows';
import EditFlows from './pages/EditFlows';

import Country from './pages/Country';
import CreateCountry from './pages/CreateCountry';
import EditCountry from './pages/EditCountry';

import Illustrations from './pages/Illustrations';
import CreateIllustrations from './pages/CreateIllustrations';
import EditIllustrations from './pages/EditIllustrations';

import Measurements from './pages/Measurements';
import CreateMeasurements from './pages/CreateMeasurements';
import EditMeasurements from './pages/EditMeasurements';

import MeasurementHeaders from './pages/MeasurementHeaders';
import CreateMeasurementHeaders from './pages/CreateMeasurementHeaders';
import EditMeasurementHeaders from './pages/EditMeasurementHeaders';

import Users from './pages/Users';
import CreateUsers from './pages/CreateUsers';
import EditUsers from './pages/EditUsers';

import Products from './pages/Products';
import CreateProducts from './pages/CreateProducts';
import EditProducts from './pages/EditProducts';

import FabricColor from './pages/FabricColor';
import CreateFabricColor from './pages/CreateFabricColor';
import EditFabricColor from './pages/EditFabricColor';

import Delivery from './pages/Delivery';
import CreateDelivery from './pages/CreateDelivery';
import EditDelivery from './pages/EditDelivery';

import Invoice from './pages/Invoice';
import EditInvoices from './pages/EditInvoice';

import MeasurementDetail from './pages/MeasurementDetail';
import CreateMeasurementDetail from './pages/CreateMeasurementDetail';
import EditMeasurementDetail from './pages/EditMeasurementDetail';

import Content from './pages/Content';
import CreateContent from './pages/CreateContent';
import EditContent from './pages/EditContent';

import Orders from './pages/Orders';
import EditOrders from './pages/EditOrders';

import Blogs from './pages/Blogs';
import CreateBlog from './pages/CreateBlog';
import EditBlog from './pages/EditBlob';

import Currency from './pages/Currency';
import CreateCurrency from './pages/CreateCurrency';
import EditCurrency from './pages/EditCurrency';

import PaymentMethods from './pages/PaymentMethods';
import CreatePaymentMethods from './pages/CreatePaymentMethods';
import EditPaymentMethods from './pages/EditPaymentMethods';
import Createflowimage from './pages/CreateFlowImage';

import EditFlowImage from './pages/EditFlowImage';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" render={props=><Index {...props}/>}/>
        <Route exact path="/grid" render={props=><Grid {...props}/>}/>
        <Route exact path="/createform" render={props=><CreateForm {...props}/>}/>
        <Route exact path="/home" render={props=><Home {...props}/>}/>

        <Route exact path="/colors" render={props=><Colors {...props}/>}/>
        <Route exact path="/createcolors" render={props=><CreateColors {...props}/>}/>
        <Route exact path="/editcolors" render={props=><EditColors {...props}/>}/>
        
        <Route exact path="/menu" render={props=><Menu {...props}/>}/>
        <Route exact path="/createmenu" render={props=><CreateMenu {...props}/>}/>
        <Route exact path="/editmenu" render={props=><EditMenu {...props}/>}/>
        
        <Route exact path="/categories" render={props=><Categories {...props}/>}/>
        <Route exact path="/createcategories" render={props=><CreateCategories {...props}/>}/>
        <Route exact path="/editcategories" render={props=><EditCategories {...props}/>}/>
        
        <Route exact path="/subcategories" render={props=><SubCategories {...props}/>}/>
        <Route exact path="/createsubcategories" render={props=><CreateSubCategories {...props}/>}/>
        <Route exact path="/editsubcategories" render={props=><EditSubCategories {...props}/>}/>
        
        <Route exact path="/fabrics" render={props=><Fabric {...props}/>}/>
        <Route exact path="/createfabrics" render={props=><CreateFabric {...props}/>}/>
        <Route exact path="/editfabrics" render={props=><EditFabric {...props}/>}/>
        
        <Route exact path="/concepts" render={props=><Concepts {...props}/>}/>
        <Route exact path="/createconcepts" render={props=><CreateConcepts {...props}/>}/>
        <Route exact path="/editconcepts" render={props=><EditConcepts {...props}/>}/>
        
        <Route exact path="/flows" render={props=><Flows {...props}/>}/>
        <Route exact path="/createflows" render={props=><CreateFlows {...props}/>}/>
        <Route exact path="/editflows" render={props=><EditFlows {...props}/>}/>

        <Route exact path="/country" render={props=><Country {...props}/>}/>
        <Route exact path="/createcountry" render={props=><CreateCountry {...props}/>}/>
        <Route exact path="/editcountry" render={props=><EditCountry {...props}/>}/>

        <Route exact path="/illustrations" render={props=><Illustrations {...props}/>}/>
        <Route exact path="/createillustrations" render={props=><CreateIllustrations {...props}/>}/>
        <Route exact path="/editillustrations" render={props=><EditIllustrations {...props}/>}/>

        <Route exact path="/measurements" render={props=><Measurements {...props}/>}/>
        <Route exact path="/createmeasurements" render={props=><CreateMeasurements {...props}/>}/>
        <Route exact path="/editmeasurements" render={props=><EditMeasurements {...props}/>}/>

        <Route exact path="/measurementheader" render={props=><MeasurementHeaders {...props}/>}/>
        <Route exact path="/createmeasurementheader" render={props=><CreateMeasurementHeaders {...props}/>}/>
        <Route exact path="/editmeasurementheader" render={props=><EditMeasurementHeaders {...props}/>}/>

        <Route exact path="/users" render={props=><Users {...props}/>}/>
        <Route exact path="/createusers" render={props=><CreateUsers {...props}/>}/>
        <Route exact path="/editusers" render={props=><EditUsers {...props}/>}/>

        <Route exact path="/products" render={props=><Products {...props}/>}/>
        <Route exact path="/createproducts" render={props=><CreateProducts {...props}/>}/>
        <Route exact path="/editproducts" render={props=><EditProducts {...props}/>}/>

        <Route exact path="/fabriccolorassignment" render={props=><FabricColor {...props}/>}/>
        <Route exact path="/createfabriccolorassignment" render={props=><CreateFabricColor {...props}/>}/>
        <Route exact path="/editfabriccolorassignment" render={props=><EditFabricColor {...props}/>}/>

        <Route exact path="/delivery" render={props=><Delivery {...props}/>}/>
        <Route exact path="/createdelivery" render={props=><CreateDelivery {...props}/>}/>
        <Route exact path="/editdelivery" render={props=><EditDelivery {...props}/>}/>

        <Route exact path="/invoices" render={props=><Invoice {...props}/>}/>
        <Route exact path="/editinvoices" render={props=><EditInvoices {...props}/>}/>

        <Route exact path="/measurementdetail" render={props=><MeasurementDetail {...props}/>}/>
        <Route exact path="/createmeasurementdetail" render={props=><CreateMeasurementDetail {...props}/>}/>
        <Route exact path="/editmeasurementdetail" render={props=><EditMeasurementDetail {...props}/>}/>

        <Route exact path="/orders" render={props=><Orders {...props}/>}/>
        <Route exact path="/editorders" render={props=><EditOrders {...props}/>}/>

        <Route exact path="/content" render={props=><Content {...props}/>}/>
        <Route exact path="/createcontent" render={props=><CreateContent {...props}/>}/>
        <Route exact path="/editcontent" render={props=><EditContent {...props}/>}/>

        <Route exact path="/blogs" render={props=><Blogs {...props}/>}/>
        <Route exact path="/createblog" render={props=><CreateBlog {...props}/>}/>
        <Route exact path="/editblog" render={props=><EditBlog {...props}/>}/>

        <Route exact path="/currency" render={props=><Currency {...props}/>}/>
        <Route exact path="/createcurrency" render={props=><CreateCurrency {...props}/>}/>
        <Route exact path="/editcurrency" render={props=><EditCurrency {...props}/>}/>

        <Route exact path="/paymentmethods" render={props=><PaymentMethods {...props}/>}/>
        <Route exact path="/createpaymentmethods" render={props=><CreatePaymentMethods {...props}/>}/>
        <Route exact path="/editpaymentmethods" render={props=><EditPaymentMethods {...props}/>}/>
        <Route exact path="/editflowimages" render={props=><EditFlowImage {...props}/>}/>
        <Route exact path="/createflowimage" render={props=><Createflowimage {...props}/>}/>
        
      </Switch>
    </Router>
  );
}

export default App;
