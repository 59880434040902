import React, { Component } from "react";
import moment from 'moment';
import { getUserData } from "../common";
import history from '../history';

export default class Spinner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user:null,
            name:""
        }
    }

    componentDidMount(){
        this.loadData();
    }

    loadData=async()=>{
        var user=await getUserData();
        var users={};
        for(var u in user){
            users[u]=user[u];
        }
        console.log("users::",users);
        this.setState({user:users});
    }

    logout=async()=>{
        localStorage.removeItem('userD');
        history.push("/");
        window.location=window.location.href;
    }

    render() {
        return (
            <nav className="page-controls navbar navbar-dashboard">

                <div className="container-fluid">
                    <div className="navbar-header mr-md-3">
                        
                    </div>

                    <div className="navbar-header mobile-hidden">
                        <form className="navbar-form" role="search">
                            <div className="form-group">
                                <div className="input-group input-group-no-border ml-4">
                                   
                                </div>
                            </div>
                        </form>
                        <ul className="nav navbar-nav float-right">
                            <li className="">
                                <a href="#" role="button" className="nav-link"
                                    id="notifications-dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false" data-position="bottom-middle-aligned" data-disable-interaction="false">
                                    <span className="thumb-sm avatar float-left">
                                        <img className="rounded-circle" src={(this.state.user!=null)?this.state.user.profile_picture:"demo/img/people/a5.jpg"} alt="..." />
                                    </span>
                                    &nbsp;
                                    {(this.state.user!==null)?this.state.user.first_name:""} <strong>{(this.state.user!==null)?this.state.user.last_name:""}</strong>&nbsp;
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="#" className="dropdown-toggle no-caret nav-link" data-toggle="dropdown">
                                    <i className="la la-cog"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    {/* <li><a className="dropdown-item" href="#"><i className="glyphicon glyphicon-user"></i> &nbsp; My
                                        Account</a></li>
                                    <li className="dropdown-divider"></li> */}
                                    {/* <li><a className="dropdown-item" href="#">Calendar</a></li>
                                    <li><a className="dropdown-item" href="#">Inbox &nbsp;&nbsp;<span
                                        className="badge badge-pill bg-danger animated bounceIn">9</span></a></li> */}
                                    <li className="dropdown-divider"></li>
                                    <li><a className="dropdown-item" href="#" onClick={()=>this.logout()}><i className="la la-sign-out"></i> &nbsp; Log Out</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}
