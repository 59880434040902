import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { $ } from 'jquery';
import queryString from 'query-string';
import { GetData, formateLabel, DeleteData, PostData, PatchData } from '../common';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            label: "",
            sublabel: "",
            breadcrumbs: [],
            gridColumns: [],
            endpoint: "",
            dataset: [],
            invoices: [],
            editLink: "",
            statuses: ["Pending", "Paid", "Payment Failed", "Delivered", "Cancelled"],
            next:"",
            previous:""
        }
    }

    componentDidMount() {
        this.loadData("");
    }

    loadData = async (url) => {
        this.setState({ loading: true });
        for (var s in this.props.data) {
            const data = this.props.data[s];
            this.setState({ s, data });
        }
        this.setState({ editLink: this.props.data.editLink });
        this.setState({ endpoint: this.props.data.endpoint });

        if(url==""){
            //measurement details
            var dataid=queryString.parse(window.location.search).id;
            let endpoint_=this.props.data.endpoint;
            if(dataid!==undefined && this.props.data.endpoint=="measurementdetails/")
                endpoint_=`${this.props.data.endpoint}?header=${dataid}`;

            var data = await GetData(endpoint_);

            if (data.code == 200) {
                this.setState({ dataset: data.message.results });
                this.setState({ next: data.message.next });
                this.setState({ previous: data.message.previous });
            }
        }else if(url!=null){
            var http=[];
            if(url.startsWith("http")){
                http=url.split("?");
            }
            var data = await GetData(`${this.props.data.endpoint}?${http[1]}`);
            if (data.code == 200) {
                this.setState({ dataset: data.message.results });
                this.setState({ next: data.message.next });
                this.setState({ previous: data.message.previous });
            }
        }
        this.setState({ loading: false });
    }

    deleteById = async (id) => {

        if (this.state.endpoint == "products/") {
            var formData = new URLSearchParams();
            formData.append("status", false);
            var data = await PatchData(this.state.endpoint + `${id}/`, formData, true);
            console.log(data);
            if (data == 200) {
                this.loadData();
            }
        }else if (this.state.endpoint == "fabrics/") {
            var formData = new URLSearchParams();
            formData.append("is_active", false);
            var data = await PatchData(this.state.endpoint + `${id}/`, formData, true);
            console.log(data);
            if (data == 200) {
                this.loadData();
            }
            window.location=window.location.href;
        } else {
            var data = await DeleteData(this.state.endpoint + `${id}/`);
            if (data == 204) {
                window.location=window.location.href;
            }
        }
    }

    render() {
        const { statuses, breadcrumbs, label, sublabel, loading, gridColumns, dataset } = this.state;
        var grid = [];
        for (var d in dataset) {
            const dat = dataset[d];
            let cols = [];
            for (var c in this.props.data.gridColumns) {
                if (["is_active", "status"].includes(this.props.data.gridColumns[c]))
                    cols.push(<td>{(dat[this.props.data.gridColumns[c]] == true) ? "Active" : "Inactive"}</td>);
                else if (this.props.data.gridColumns[c] == "group")
                    cols.push(<td>{(dat[this.props.data.gridColumns[c]] == 1) ? "Client" : "Admin"}</td>);
                else if (this.props.data.gridColumns[c] == "can_place_order")
                    cols.push(<td>{(dat[this.props.data.gridColumns[c]] == true) ? "YES" : "NO"}</td>);
                else if (this.props.data.gridColumns[c] == "hex_code") {
                    var color = dat[this.props.data.gridColumns[c]];
                    cols.push(<td><div className="text-center" style={{ backgroundColor: color, width: 120, lineHeight: 3 }}><strong>{dat[this.props.data.gridColumns[c]]}</strong></div></td>);
                } else if (["image", "flag", "thumbs"].includes(this.props.data.gridColumns[c])) {
                    var color = dat[this.props.data.gridColumns[c]];
                    cols.push(<td><img src={dat[this.props.data.gridColumns[c]]} height="60" style={{ borderRadius: 20 }} /></td>);
                } else if (this.props.data.gridColumns[c] == "images") {
                    var images = dat[this.props.data.gridColumns[c]];
                    var imgs = [];
                    for (var im in images) {
                        const data = images[im];
                        if (im < 2)
                            imgs.push(<img src={data.image} width="40" style={{ marginRight: 10, borderRadius: 10, border: '1px solid #ccc', padding: 5, background: '#fff' }} />);
                    }
                    cols.push(<td>{imgs}</td>);
                } else if (this.props.data.endpoint == "orders/" && this.props.data.gridColumns[c] == "order_status") {
                    cols.push(<td>{statuses[dat[this.props.data.gridColumns[c]]]}</td>);
                } else
                    cols.push(<td>{dat[this.props.data.gridColumns[c]]}</td>);
            }
            cols.push(<td width="5%"><a href={this.state.editLink + dat['id'] + ""}><i class="la la-pencil" style={{ fontSize: 20, color: '#222' }}></i></a></td>);
            cols.push(<td width="5%"><a href="#"><i class="la la-trash" style={{ fontSize: 20, color: '#222' }} onClick={() => this.deleteById(dat['id'])}></i></a></td>);
            grid.push(<tr>{cols}</tr>);
        }

        return (
            <>
                {(loading == true) && <center><Spinner bg="#fff" fill="#f00" /><br /><br /></center>}
                <table className="table table-striped">
                    <thead>
                        <tr>
                            {this.props.data.gridColumns.map(data => {
                                return <th>{formateLabel(data)}</th>
                            })}
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {grid}
                    </tbody>

                </table>
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" href="#" onClick={()=>this.loadData(this.state.previous)}>Previous</a></li>
                        <li class="page-item"><a class="page-link" href="#" onClick={()=>this.loadData(this.state.next)}>Next</a></li>
                    </ul>
                </nav>
            </>
        );
    }
}