import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { $ } from 'jquery';
import { formData,GetData,generateDropdownData } from '../common';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Products",
            sublabel: "Products",
            breadcrumbs: [{label:"Products",href:"/products"}, {label:"Products Management",href:"/products"}],
            endpoint:"products/",
            pageLinks:[{label:"Manage Products",link:"/products"}],
            columns:[
                { label: "Name", name: "name", required: true, type: "text"},
                { label: "Description", name: "description", required: true, type: "text"},
                { label: "Category", name: "category_id", required: true, type: "select"},
                { label: "Color", name: "color", required: false, type: "select"},
                { label: "SKU", name: "sku", required: true, type: "text"},
                { label: "Price", name: "price", required: true, type: "number"},
                { label: "Discount", name: "discount", required: true, type: "number"},
                { label: "Created User", name: "created_user", required: false, type: "number"},
                { label: "Modified User", name: "modified_user", required: false, type: "number"},
                { label: "Regular Delivery", name: "regular_delivery", required: false, type: "number"},
                { label: "Express Delivery", name: "express_delivery", required: false, type: "number"},
                { label: "Images", name: "images", required: false, type: "file"},
                { label: "Features", name: "features", required: false, type: "text"},
                { label: "Sizes", name: "sizes", required: false, type: "text"}
            ],
            payload:{

            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var colors_list=[];
        var looperList=true;
        var url_="colors/";
        while(looperList==true){
            var colors=await GetData(url_);
            var nList=[];
            for(var c in colors.message.results){
                const cat=colors.message.results[c];
                nList.push({ value: `${cat.id}|${cat.parent_id}|${cat.menu_id}`, label: `${cat.menu_name} - ${cat.parent_id_name} - ${cat.name}` });
            }
            colors_list=[...colors_list, ...generateDropdownData(colors,"id|name")];
            if(colors.message.next!==null){
                url_="colors/?"+colors.message.next.split("?")[1];
            }else{
                looperList=false;
            }
        }

        // var cat_list=generateDropdownData(categories,"id|menu_name|parent_id_name|name");
        var cat_list=[];
        looperList=true;
        var url_="subcategories/?is_active=true";
        while(looperList==true){
            var categories=await GetData(url_);
            for(var c in categories.message.results){
                const cat=categories.message.results[c];
                cat_list.push({ value: `${cat.id}|${cat.parent_id}|${cat.menu_id}`, label: `${cat.menu_name} - ${cat.parent_id_name} - ${cat.name}` });
            }
            if(categories.message.next!==null){
                url_="subcategories/?"+categories.message.next.split("?")[1];
            }else{
                looperList=false;
            }
        }
        
        
        

        var columns=this.state.columns;
        columns[2]['data']=cat_list;
        columns[3]['data']=colors_list;

        this.setState({columns:columns});
    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        console.log(payload)
        this.setState({ payload: payload });
    }

    

    render() {
        const { breadcrumbs, label, sublabel,columns,pageLinks} = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                {pageLinks.map(data=>{
                                                return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                            })}
                                </div>
                            </header>
                            <div class="widget-body">
                                
                                <div class="row">
                                    <Form data={this.state}/>     
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}