import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/EditForm';
import { $ } from 'jquery';
import { formData,GetData,generateDropdownData } from '../common';
import queryString from 'query-string';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Orders",
            sublabel: "Orders",
            breadcrumbs: [{ label: "Orders Management", href: "/orders" }],
            endpoint: "orders/",
            pageLinks: [{ label: "Manage Orders", link: "/orders" }],
            columns:[
                {label:"Name",name:"user_fullname",required:true,type:"text"},
                {label:"Address",name:"user_address2",required:false,type:"text"},
                {label:"Order Date  ",name:"ordering_date",required:false,type:"fixed"},
                {label:"Order Status ",name:"order_status",required:false,type:"select",data:[{label:"Delivered",value:3}, {label:"Cancelled",value:4}]},
                {label:"Payment Method ",name:"payment_method",required:true,type:"select-fixed"},
                {label:"Shipping Method ",name:"shipping_method",required:false,type:"select-fixed"},
                { label: "Currency", name: "currency_name", required: true, type: "fixed"},
                { label: "Order Total", name: "total_price", required: true, type: "fixed"},
                {label:"Transaction ID",name:"transaction_id",required:true,type:"fixed"},
                {label:"Comments ",name:"comments",required:false,type:"fixed"},
                {label:"Remark ",name:"remark",required:false,type:"blob"},
            ],
            payload: {
            },
            id: ""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var payments=await GetData("paymentmethods/");
        var shipping=await GetData("shippingmethods/?is_active=true");

        var payments_list=generateDropdownData(payments,"id|title");
        var shipping_list=generateDropdownData(shipping,"id|name");

        console.log(payments_list)

        var columns=this.state.columns; 
        columns[4]['data']=payments_list;
        columns[5]['data']=shipping_list;
        this.setState({columns:columns});

    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });


        this.setState({ payload: payload });
    }



    render() {
        const { breadcrumbs, label, sublabel, columns, pageLinks } = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">
                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                    {pageLinks.map(data => {
                                        return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                    })}
                                </div>
                            </header>
                            <div class="widget-body">

                                <div class="row">
                                    <Form data={this.state} id={queryString.parse(this.props.location.search).id} />
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}