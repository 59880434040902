import React, { Component } from "react";
import moment from 'moment';
import Spinner from './Spinner';
import { formatErrors, GetData, getUserData, PostData, urls } from '../common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: null,
            label: "",
            alertType: "alert-success",
            errorMsg: "",
            endpoint: "",
            fileUpload: null,
            otherFile:{},
            payload: {},
            images: false,
            features: false,
            featuresList: [],
            featureInput: "",
            loading:false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ columns: this.props.data.columns });
        this.setState({ label: this.props.data.label });
        this.setState({ endpoint: this.props.data.endpoint });
        var payload = {};
        for (var c in this.props.data.columns) {
            if (this.props.data.columns[c].name == "features") {
                this.setState({ features: true });
            }
            payload[this.props.data.columns[c].name] = "";
        }
        this.setState({ payload: payload });
    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });
    }

    onFileChange = async (event) => {
        var files = (this.state.fileUpload == null) ? [] : this.state.fileUpload;
        for (var f in event.target.files) {
            if (f != "item" && f != "length")
                files.push(event.target.files[f]);
        }
        this.setState({ fileUpload: files });
    }

    onOtherFileChange = async (event, name) => {
        var filesLabels = this.state.otherFile;
        var files = [];
        for (var f in event.target.files) {
            if (f != "item" && f != "length")
                files.push(event.target.files[f]);
        }
        filesLabels[name] = files;
        this.setState({ otherFile: filesLabels });
    }

    SubmitForm = async () => {
        var valid = true;

        var errorLabels = "";
        for (var f in this.state.columns) {
            const data = this.state.columns[f];
            if (data.required == true) {
                if (this.state.payload[data.name] == undefined || this.state.payload[data.name] == "") {
                    valid = false;

                    errorLabels += data.name + ", ";
                }
            }
        }

        var user = await getUserData();
        if (valid == false) {
            this.setState({ alertType: "alert-danger", errorMsg: "Kindly fill the following fields " + errorLabels.slice(0, -2) })
        } else {
            this.setState({loading:true});
            var formData = new URLSearchParams();
            console.log("-------",this.state.fileUpload);
            console.log("-------",this.state.fileUpload);
            if (this.state.fileUpload !== null ||  Object.keys(this.state.otherFile).length > 0) {
                formData = new FormData();
                for (var img in this.state.fileUpload) {
                    var key_ = (["fabrics/"].includes(this.props.data.endpoint)) ? "" : "image";
                    if (["users/"].includes(this.props.data.endpoint)) key_ = "profile_picture";
                    if (["flows/"].includes(this.props.data.endpoint)) key_ = "images";
                    if (["products/"].includes(this.props.data.endpoint)) key_ = "images_"+img;

                    formData.append(key_, this.state.fileUpload[img], this.state.fileUpload[img].name);
                }
            }

            for (var p in this.state.payload) {
                if (["created_user_id", "modified_user_id", "user_id", "updatedby", "created_user", "modified_user"].includes(p)) {
                    formData.append(p, user.id);
                } else
                    formData.append(p, this.state.payload[p]);
            }

            for (var img in this.state.otherFile) {
                for (var i in this.state.otherFile[img]) {
                    const list = this.state.otherFile[img][i];
                    formData.append(img, list, list.name);
                }
            }

            if (this.state.features == true) {
                formData.append("features", this.state.featuresList.join("|"));
            }

            //Product menu , category, sub category
            if (this.state.endpoint == "products/") {
                var menus=this.state.payload["category_id"].split("|");
                formData.append("menu",menus[2]);
                formData.append("category",menus[1]);
                formData.append("subcategory",menus[0]);
            }
            
            var createData = null;
            if (this.state.fileUpload == null)
                createData = await PostData(this.state.endpoint, formData, true);
            else
                createData = await PostData(this.state.endpoint, formData, true, "file");

            if (createData == null) {
                this.setState({ errorMsg: "Sorry unable to process your request at this time." });
                this.setState({ alertType: "alert-danger" });
            } else {
                if (createData.code == 201) {
                    this.setState({ errorMsg: this.state.label + " created successfully", alertType: "alert-success" });
                    this.clearForm();
                } else {
                    var errors = formatErrors(createData.message);
                    this.setState({ errorMsg: errors });
                    this.setState({ alertType: "alert-danger" });
                }
            }

            this.setState({loading:false});
        }
    }

    clearForm = async () => {
        var payload = this.state.payload;
        for (var p in payload) {
            payload[p] = "";
        }

        this.setState({ payload: payload, fileUpload: null });
    }

    render() {
        const { columns, label, alertType, errorMsg, payload, fileUpload } = this.state;

        var formData = [];
        if (columns != null) {
            for (var f in columns) {
                var width=6;
                if (['features', 'created_user_id', 'modified_user_id', 'is_active', 'user_id', 'updatedby', "created_user", "modified_user"].includes(columns[f].name)) { }
                else {
                    var input = <input type={columns[f].type} className="form-control" name={columns[f].name} placeholder={(columns[f].placeholder == undefined) ? "" : columns[f].placeholder}
                        onChange={(e) => this.onChange(e)} value={payload[columns[f].name]} />;
                    if (columns[f].type == "select") {
                        var optionList = [];
                        for (var d in columns[f].data) {
                            optionList.push(<option class="dropdown-item" value={columns[f].data[d].value}>{columns[f].data[d].label}</option>);
                        }
                        input = <select name={columns[f].name} tabindex="-1" class="select2 form-control" id="default-select"
                            onChange={(e) => this.onChange(e)} value={payload[columns[f].name]}>
                            <option class="dropdown-item">Select {columns[f].label}</option>
                            {optionList}
                        </select>
                    } else if (columns[f].type == "file") {
                        input = <input type={columns[f].type} className="form-control" name="files[]"
                            onChange={(e) => this.onFileChange(e)} multiple />;
                    }else if(columns[f].type == "blob"){
                        width=12;
                        input=<CKEditor
                        editor={ ClassicEditor }
                        data={payload[columns[f].name]}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log( 'Editor is ready to use!', editor );
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            
                            var payload = this.state.payload;
                            payload["content"] = data;
                            console.log("data::",data);
                            this.setState({ payload: payload });
                        } }
                        onBlur={ ( event, editor ) => {
                            console.log( 'Blur.', editor );
                        } }
                        onFocus={ ( event, editor ) => {
                            console.log( 'Focus.', editor );
                        } }
                    />;
                    }

                    formData.push(<div class={`col-lg-${width} form-group`} style={{ marginBottom: 10 }}>
                        <label for="normal-field" class="form-control-label text-md-left">{columns[f].label}</label>
                        {input}
                    </div>);
                }
            }
        }

        //image upload
        var files = [];
        if (fileUpload != null) {
            for (var f in fileUpload) {
                const img = fileUpload[f];
                files.push(<img src={URL.createObjectURL(img)} height="120" style={{ marginRight: 20, borderRadius: 20 }} />);
            }
        }

        //Product features 
        var featureInput = [];
        if (this.state.features == true) {
            var fList = [];
            for (var f in this.state.featuresList) {
                fList.push(<div type="submit" class="btn btn-outline-dark mr-3 col-auto mb-3">
                    {this.state.featuresList[f]}
                    <a style={{ cursor: 'pointer' }} onClick={() => {
                        var fDelete = this.state.featuresList;
                        var newList = fDelete.filter(data => {
                            return data != this.state.featuresList[f];
                        });
                        this.setState({ featuresList: newList });
                    }}><i className="la la-trash"></i></a>
                </div>);
            }

            featureInput.push(<div class="col-lg-12 ml-10 mt-10 form-group" style={{ marginBottom: 10 }}>
                {fList}
            </div>);

            featureInput.push(<div class="col-lg-6 form-group" style={{ marginBottom: 10 }}>
                <label for="normal-field" class="form-control-label text-md-left">{columns[12].label}<small>(Press enter to separate them)</small></label>
                <input type="text" className="form-control" name={columns[f].name} placeholder={(columns[12].placeholder == undefined) ? "" : columns[12].placeholder}
                    onChange={(e) => { console.log(this.state.featureInput); this.setState({ featureInput: e.target.value }) }} value={this.state.featureInput}
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            var fl = this.state.featuresList;
                            fl.push(e.target.value);
                            this.setState({ featuresList: fl, featureInput: "" });
                        }
                    }} />
            </div>);
        }

        return (
            <>
                {(errorMsg != "") &&
                    <div class="col-lg-8">
                        <div class={"alert " + alertType + " alert-sm alert-transparent"}>
                            {errorMsg}
                        </div>
                    </div>}
                <div class="col-lg-12">
                    {files}
                </div>
                {formData}

                {featureInput}
                <br />
                {(this.state.loading==false)?
                <div class="form-actions bg-transparent col-12">
                    <button type="submit" class="btn btn-inverse mr-3" onClick={() => this.SubmitForm()}>Create {label}</button>
                    <button type="reset" class="btn btn-default">Cancel</button>
                </div>: <center><Spinner bg="#fff" fill="#005792" /><br/><br/></center>}
            </>
        )
    }
}
