import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { $ } from 'jquery';
import { GetData } from '../common';
import moment from 'moment';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Grid Label",
            sublabel: "Grid Label",
            breadcrumbs: [{ label: "Feature Name", href: "" }, { label: "Operation", href: "" }],
            gridColumns: ["id", "name"],
            dataSet: [],
            currencies: [],
            dashboard: null,
            statuses: ["Pending", "Paid", "Payment Failed", "Delivered", "Cancelled"],
        }
    }

    componentDidMount() {
        this.loadData("");
    }

    loadData = async (url) => {
        console.log("url::",url);
        var orders = null;
        if (url == "")
            orders = await GetData("orders/?order_status=1");
        else {
            var http=[];
            if(url.startsWith("http")){
                http=url.split("?");
            }
            orders = await GetData(`orders/?${http[1]}`);
        }

        if (orders != null) {
            this.setState({ dataSet: orders.message.results });
            this.setState({ next: orders.message.next });
            this.setState({ previous: orders.message.previous });
        }else{

        }

        var dashboard = await GetData("dashboard/");
        this.setState({ dashboard: dashboard.message });

        // var requestURL = 'https://api.exchangerate.host/latest';
        // var request = new XMLHttpRequest();
        // request.open('GET', requestURL);
        // request.responseType = 'json';
        // request.send();

        // request.onload = function() {
        // var response = request.response;
        // console.log(response);
        // }

        var getCurrencies = await GetData(`currency/`);
        if (getCurrencies.code == 200) {
            this.setState({ currencies: getCurrencies.message.results });
        }

    }

    render() {
        const { dashboard, dataSet, label, sublabel, statuses, currencies } = this.state;

        let stats = [];
        for (var d in dashboard) {
            stats.push(<div className="col-2 mb-xlg">
                <div className="pb-xlg h-100">
                    <section className="widget mb-0 h-100">
                        <header className="d-flex justify-content-between flex-wrap">
                            <h4 className="d-flex align-items-center pb-1" style={{ fontSize: 16 }}>
                                <span className="circle bg-danger mr-sm" style={{ fontSize: 6 }}></span>
                                {d.toUpperCase()}
                            </h4>
                        </header>
                        <div className="widget-body p-0">
                            <h4 className="fw-semi-bold ml-lg mb-lg">{dashboard[d]}</h4>
                        </div>
                    </section>
                </div>
            </div>);
        }
        return (
            <>
                <Header />
                <Menu />
                <div className="content-wrap">
                    <main id="content" className="content" role="main" >
                        <div className="analytics">
                            <div className="analytics-side" style={{ width: '100%' }}>
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="page-title" style={{ fontSize: 20 }}>
                                            Users & Order Statistics
                                        </h1>
                                    </div>
                                    {stats}

                                    <div className="col-12">
                                        <h1 className="page-title" style={{ fontSize: 20 }}>
                                            Exchange Rates
                                        </h1>
                                    </div>
                                    {currencies.map((data, index) => {
                                        return <div className="col-4 mb-xlg">
                                            <div className="pb-xlg h-10">
                                                <section className="widget mb-0 h-100">
                                                    <header className="d-flex justify-content-between flex-wrap">

                                                        <h4 className="d-flex align-items-center pb-1 big-stat-title">
                                                            <span className="circle bg-danger mr-sm" style={{ fontSize: 6 }}></span>
                                                            <a href={`/editcurrency?id=${data.id}`}>{data.name}</a> <small className="fw-normal ml-xs">Currency {(data.name.toLowerCase() == "usd") ? "( default )" : ""}</small>
                                                        </h4>
                                                    </header>
                                                    <div className="widget-body pb-10">
                                                        <h4 className="fw-semi-bold ml-lg mb-lg">{data.rate}</h4>
                                                        <small className="fw-semi-bold ml-lg mb-lg">Last Updated : <strong>{moment(data.last_updated).format("DD, MMM YYYY HH:mm:ss")}</strong></small>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>;
                                    })}
                                    <div className="col-12">
                                        <h1 className="page-title" style={{ fontSize: 20 }}>
                                            Orders
                                        </h1>
                                    </div>
                                    <div className="col-12 mb-lg">
                                        <section className="widget pb-0">
                                            <header>
                                                <h4>
                                                    Paid <strong>Orders</strong>
                                                </h4>
                                            </header>
                                            <div className="widget-body p-0 support table-wrapper">
                                                {(dataSet.length > 0) &&
                                                    <table className="table table-striped mb-0">
                                                        <thead>
                                                            <tr className="text-muted">
                                                                <th scope="col" style={{ width: '1%', textAlign: 'center' }}>ID</th>
                                                                <th scope="col">Transaction ID</th>
                                                                <th scope="col">Customer</th>
                                                                <th scope="col"><span className=" pl-3">Total</span></th>
                                                                <th scope="col">Order Date</th>
                                                                <th scope="col">Order Status</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-dark">
                                                            {(this.state.dataSet.map(function (data, index) {
                                                                return <tr>
                                                                    <td style={{ width: '1%', textAlign: 'center' }}>{data.id}</td>
                                                                    <td>{data.transaction_id}</td>
                                                                    <td>{data.user_fullname}</td>
                                                                    <th className="pl-4 fw-normal">{data.currency_name} {data.total_price}</th>
                                                                    <td>{data.ordering_date}</td>
                                                                    <td>{statuses[data.order_status]}</td>
                                                                    <td>
                                                                        <a href={"editorders?id=" + data['id']}><i class="la la-pencil" style={{ fontSize: 20, color: '#222' }}></i></a>
                                                                    </td>
                                                                </tr>
                                                            }))}
                                                        </tbody>
                                                    </table>}

                                                {(dataSet.length > 0) &&
                                                    <nav aria-label="Page navigation example">
                                                        <ul class="pagination">
                                                            <li class="page-item"><a class="page-link" onClick={() => this.loadData(this.state.previous)}>Previous</a></li>
                                                            <li class="page-item"><a class="page-link" onClick={() => this.loadData(this.state.next)}>Next</a></li>
                                                        </ul>
                                                    </nav>}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div className="loader-wrap hiding hide">
                    <i className="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}