import React, { Component } from "react";
import moment from 'moment';

export default class Spinner extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <>
                <footer className="content-footer">
                    &copy; {moment().format("YYYY")} Taussh.com. All rights reserved
                </footer>
            </>
        )
    }
}
