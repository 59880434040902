import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/EditForm';
import { $ } from 'jquery';
import { formData,GetData,generateDropdownData ,generateDropdownDataPlain} from '../common';
import queryString from 'query-string';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Products",
            sublabel: "Products",
            breadcrumbs: [{ label: "Products", href: "/products" }, { label: "Product Management", href: "/products" }],
            endpoint: "products/",
            pageLinks: [{ label: "Manage Products", link: "/products" }],
            columns: [
                { label: "Name", name: "name", required: true, type: "text"},
                { label: "Description", name: "description", required: true, type: "text"},
                { label: "Category", name: "category_id", required: true, type: "select"},
                { label: "Color", name: "color", required: false, type: "select"},
                { label: "SKU", name: "sku", required: true, type: "text"},
                { label: "Price", name: "price", required: true, type: "number"},
                { label: "Created User", name: "created_user", required: false, type: "text"},
                { label: "Modified User", name: "modified_user", required: false, type: "text"},
                { label: "Features", name: "features", required: false, type: "text"},
                { label: "Discount", name: "discount", required: false, type: "text"},
                { label: "Measurement Type", name: "measurement_id", required: false, type: "select"},
                { label: "Regular Delivery", name: "regular_delivery", required: false, type: "number"},
                { label: "Express Delivery", name: "express_delivery", required: false, type: "number"},
                { label: "Images", name: "images", required: false, type: "file"},
                { label: "Sizes ( | ) seperated", name: "sizes", required: false, type: "text"},
                {label:"Status",name:"status",required:false,type:"select",data:[{label:"Active",value:true},{label:"Inactive",value:false}]},
            ],
            extraForms:[
                {
                    label:"Add Fabrics",
                    sublabel:"",
                    endpoint:"productfabricassignment/",
                    columns:[
                        { label: "", name: "productfabricassignment", required: false, type: "imagecheckbox",data:[],values:[]},
                    ],
                    payload:[]
                },
                {
                    label:"Add Flows",
                    sublabel:"",
                    endpoint:"productflowassignment/",
                    columns:[
                        { label: "", name: "productflowassignment", required: false, type: "imagecheckbox",data:[],values:[]},
                    ],
                    payload:[]
                },
                {
                    label:"Add Illustrator",
                    sublabel:"",
                    endpoint:"productillustrationsassignment/",
                    columns:[
                        { label: "", name: "productillustrationsassignment", required: false, type: "imagecheckbox",data:[],values:[]},
                    ],
                    payload:[]
                }
                ,{
                    label:"Add Concepts",
                    sublabel:"",
                    endpoint:"productconceptassignment/",
                    columns:[
                        { label: "", name: "productconceptassignment", required: false, type: "imagecheckbox",data:[],values:[]},
                    ],
                    payload:[]
                }
            ],
            payload: {

            },
            id: ""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    getList=async(url_,prefix)=>{
        var colors_list=[];
        var looperList=true;
        while(looperList==true){
            var colors=await GetData(url_);
            var nList=[];
            colors_list=[...colors_list, ...colors.message.results];
            if(colors.message.next!==null){
                url_=prefix+colors.message.next.split("?")[1];
            }else{
                looperList=false;
            }
        }

        return colors_list;
    }

    loadData = async () => {
        var categories=await this.getList("subcategories/?is_active=true","subcategories/?");
        var colors=await this.getList("colors/","colors/?");
        
        var cat_list=[];
        for(var c in categories){
            const cat=categories[c];
            cat_list.push({ value: `${cat.id}|${cat.parent_id}|${cat.menu_id}`, label: `${cat.menu_name} - ${cat.parent_id_name} - ${cat.name}` });
        }
        var colors_list=generateDropdownDataPlain(colors,"id|name");

        var measurementheader=await this.getList("measurementheader/","measurementheader/?");
        var measurementheader_list=generateDropdownDataPlain(measurementheader,"id|type");

        var columns=this.state.columns;
        columns[2]['data']=cat_list;
        columns[3]['data']=colors_list;
        columns[10]['data']=measurementheader_list;

        this.setState({columns:columns});

        /**
         * Fabrics Assignment
         */
        var fabrics=await this.getList("fabrics/?is_active=true","fabrics/?");
        var extraForms=this.state.extraForms;
        extraForms[0]['columns'][0].data=generateDropdownDataPlain(fabrics,"id|name","image");
        
        var productfabricassignment=await this.getList("productfabricassignment/?product="+queryString.parse(this.props.location.search).id,"productfabricassignment/?");
        if(productfabricassignment.length>0){
            var values=[];
            for(var v in productfabricassignment){
                values.push(productfabricassignment[v].fabric);
            }
            extraForms[0]['columns'][0].values=values;
        }
        console.log(extraForms);

        /**
         * Flows Assignment
         */
        var flows=await this.getList("flows/?is_active=true","flows/?");
        extraForms[1]['columns'][0].data=generateDropdownDataPlain(flows,"id|name","images");
        
        var productflowassignment=await this.getList("productflowassignment/?product_id="+queryString.parse(this.props.location.search).id,"productflowassignment/?");
        if(productflowassignment.length>0){
            var values=[];
            for(var v in productflowassignment){
                values.push(productflowassignment[v].flow_id);
            }
            extraForms[1]['columns'][0].values=values;
        }

        //illustrations
        var illustrations=await this.getList("illustrations/?is_active=true","illustrations/?");
        extraForms[2]['columns'][0].data=generateDropdownDataPlain(illustrations,"id|name","image");
        
        var productillustrationsassignment=await this.getList("productillustrationsassignment/?product_id="+queryString.parse(this.props.location.search).id,"productillustrationsassignment/?");
        if(productillustrationsassignment.length>0){
            var values=[];
            for(var v in productillustrationsassignment){
                values.push(productillustrationsassignment[v].illustrations_id);
            }
            extraForms[2]['columns'][0].values=values;
        }

        //concepts
        var concepts=await this.getList("concepts/?is_active=true","concepts/?");
        extraForms[3]['columns'][0].data=generateDropdownDataPlain(concepts,"id|name","image");
        var productconceptassignment=await this.getList("productconceptassignment/?product="+queryString.parse(this.props.location.search).id,"productconceptassignment/?");
        if(productconceptassignment.length>0){
            var values=[];
            for(var v in productconceptassignment){
                values.push(productconceptassignment[v].concept);
            }
            extraForms[3]['columns'][0].values=values;
        }

        this.setState({extraForms:extraForms});
    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });


        this.setState({ payload: payload });
    }

    render() {
        const { breadcrumbs, label, sublabel, columns, pageLinks } = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                    {pageLinks.map(data => {
                                        return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                    })}
                                </div>
                            </header>
                            <div class="widget-body">

                                <div class="row">
                                    <Form data={this.state} id={queryString.parse(this.props.location.search).id} />
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}