import React, { Component } from "react";
import moment from 'moment';
import history from '../history';

export default class Spinner extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        // var login=await localStorage.getItem("userD");
        // if(login==null){
        //     history.push("/")
        //     window.location=window.location.href;
        // }
    }

    render() {
        return (
            <nav id="sidebar" className="sidebar" role="navigation">
                <div className="js-sidebar-content">
                    <br /><br />
                    <header className="logo d-none d-md-block">
                        <a href="/home"><img className="" src="img/logo.png" alt="..." /></a>
                    </header>

                    <ul className="sidebar-nav">
                        <li className="">

                            <a href="/home">
                                <span className="icon">
                                    <i className="fi flaticon-home"></i>
                                </span>
                                Dashboard
                            </a>
                        </li>
                    </ul>
                    <h5 className="sidebar-nav-title">User</h5>
                    <ul className="sidebar-nav">
                        <li className="">
                            <a className="collapsed" href="#user-ui" data-toggle="collapse" data-parent="#sidebar">
                                <span className="icon">
                                    <i className="fi flaticon-layers"></i>
                                </span>
                                User Management
                                <i className="toggle fa fa-angle-down"></i>
                            </a>
                            <ul id="user-ui"
                                className="collapse ">
                                <li className=""><a href="/users">Users</a></li>
                            </ul>
                        </li>
                    </ul>
                    <h5 className="sidebar-nav-title">Product</h5>
                    <ul className="sidebar-nav">
                        <li className="">
                            <a className="collapsed" href="#Product-ui" data-toggle="collapse" data-parent="#sidebar">
                                <span className="icon">
                                    <i className="fi flaticon-layers"></i>
                                </span>
                                Product Management
                                <i className="toggle fa fa-angle-down"></i>
                            </a>
                            <ul id="Product-ui"
                                className="collapse ">
                                <li className=""><a href="/products">Products</a></li>
                                <li className=""><a href="/fabrics">Fabrics</a></li>
                                <li className=""><a href="/fabriccolorassignment">Fabric Colors</a></li>
                                <li className=""><a href="/flows">Flows</a></li>
                                <li className=""><a href="/concepts">Concepts</a></li>
                                <li className=""><a href="/illustrations">Illustration</a></li>
                                {/* <li className=""><a href="/measurements">Measurements</a></li> */}
                                <li className=""><a href="/measurementheader">Measurement Headers</a></li>
                                <li className=""><a href="/measurementdetail">Measurement Details</a></li>
                            </ul>
                        </li>
                    </ul>

                    <h5 className="sidebar-nav-title">Product Settings</h5>
                    <ul className="sidebar-nav">
                        <li className="">
                            <a className="collapsed" href="#Product-settings" data-toggle="collapse" data-parent="#sidebar">
                                <span className="icon">
                                    <i className="fi flaticon-layers"></i>
                                </span>
                                Settings
                                <i className="toggle fa fa-angle-down"></i>
                            </a>
                            <ul id="Product-settings"
                                className="collapse ">
                                <li className=""><a href="/menu">Menu</a></li>
                                <li className=""><a href="/categories">Categories</a></li>
                                <li className=""><a href="/subcategories">Sub Categories</a></li>
                                <li className=""><a href="/colors">Colors</a></li>
                            </ul>
                        </li>
                    </ul>

                    <h5 className="sidebar-nav-title">Orders Management</h5>
                    <ul className="sidebar-nav">
                        <li className="">
                            <a className="collapsed" href="#Orders-ui" data-toggle="collapse" data-parent="#sidebar">
                                <span className="icon">
                                    <i className="fi flaticon-layers"></i>
                                </span>
                                Orders
                                <i className="toggle fa fa-angle-down"></i>
                            </a>
                            <ul id="Orders-ui"
                                className="collapse ">
                                <li className=""><a href="/orders">Orders</a></li>
                                {/* <li className=""><a href="/invoices">Invoices</a></li> */}

                            </ul>
                        </li>
                    </ul>
                    <h5 className="sidebar-nav-title">Others Settings</h5>
                    <ul className="sidebar-nav">
                        <li className="">
                            <a className="collapsed" href="#Others-ui" data-toggle="collapse" data-parent="#sidebar">
                                <span className="icon">
                                    <i className="fi flaticon-settings"></i>
                                </span>
                                Settings
                                <i className="toggle fa fa-angle-down"></i>
                            </a>
                            <ul id="Others-ui"
                                className="collapse ">
                                <li className=""><a
                                    href="/delivery">Delivery Settings</a></li>
                                <li className=""><a
                                    href="/paymentmethods">Payment Methods</a></li>
                                    
                                <li className=""><a href="/country">Countries</a></li>
                                <li className=""><a href="/currency">Currency</a></li>
                                <li className=""><a href="/content">Website content</a></li>
                                <li className=""><a href="/blogs">Blog</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>

        )
    }
}
