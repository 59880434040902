import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { $ } from 'jquery';
import { formData, generateDropdownData, GetData } from '../common';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading: false,
            label: "Illustrations",
            sublabel: "Illustrations",
            breadcrumbs: [{label:"Illustrations",href:"/products"}, {label:"Illustrations",href:"/illustrations"}],
            endpoint:"illustrations/",
            pageLinks:[{label:"Manage Illustrations",link:"/illustrations"}],
            columns:[
                {label:"Name",name:"name",required:true,type:"text"},
                {label:"Image",name:"image",required:false,type:"file"},
                {label:"Content",name:"content",required:false,type:"text"},
            ],
            payload:{

            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        //Add parent list to column
        var menus=await GetData("menu/?is_active=true");
        var list=generateDropdownData(menus,"id|name");
        var columns=this.state.columns;
        columns[4]['data']=list;
        this.setState({columns:columns});
    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });
    }

    

    render() {
        const { breadcrumbs, label, sublabel,columns,pageLinks} = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                {pageLinks.map(data=>{
                                                return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                            })}
                                </div>
                            </header>
                            <div class="widget-body">
                                
                                <div class="row">
                                    <Form data={this.state}/>     
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}