import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { $ } from 'jquery';
import { formData } from '../common';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endpoint:"/user",
            loading: false,
            label: "Grid Label",
            sublabel: "Grid Label",
            breadcrumbs: [{ label: "Feature Name", href: "" }, { label: "Operation", href: "" }],
            columns:[
                {label:"First Name",name:"fname",required:true,type:"text"},
                {label:"First Name",name:"fname",required:true,type:"text"},
                {label:"Gender",name:"fname",required:true,type:"select",data:[
                    {label:"Male",value:"Male"},
                    {label:"Female",value:"Female"}
                ]},
                {label:"Photo",name:"image",required:true,type:"file"},
            ],
            payload:{

            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {

    }

    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });
    }

    

    render() {
        const { breadcrumbs, label, sublabel,columns } = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                    <a title="Properties" href="#"><i class="glyphicon glyphicon-cog"></i></a>
                                    <a data-widgster="close" title="Close" href="#"><i class="la la-remove"></i></a>
                                </div>
                            </header>
                            <div class="widget-body">
                                
                                <div class="row">
                                    <Form data={this.state}/>     
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}