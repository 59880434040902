import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import {PostData,urls,GetData} from '../common';
import { $ } from 'jquery';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            username:"",
            password:"",
            errorMsg:""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {

    }

    login=async()=>{
        if (this.state.username === "") {
            this.setState({ errorMsg: "Username or email address is required" });
        } else if (this.state.password === "") {
            this.setState({ errorMsg: "Password is required" });
        } else {
            this.setState({ loading: true });
            var formData = new URLSearchParams();
            formData.append("username", this.state.username);
            formData.append("password", this.state.password);

            var getToken = await PostData(urls['token'], formData, false);
            if (getToken.code==200) {
                localStorage.setItem("token",getToken.message.access);

                this.setState({ loading: true });
                var login = await PostData(urls.login, formData, false);

                if (login.message.success == "True") {
                    this.getToken(formData);
                } else {
                    this.setState({ errorMsg: login.message.message });
                }
            } else if (getToken.code==5000) {
                this.setState({ errorMsg: getToken.message });
            }else {
                console.log(getToken);
                this.setState({ errorMsg: getToken.message.detail });
            }

            this.setState({ loading: false });
        }
    }

    /**
     * Generate token
     */
     getToken = async (formData) => {
        var token = await PostData(urls['token'], formData, false);

        if (token.code == 200) {
            //Refresh the token
            localStorage.setItem("token_refreh", token.message.refresh);
            formData.append("refresh", token.message.refresh);
            var tokenRefresh = await PostData(urls['tokenRefresh'],formData, false);

            if (tokenRefresh.code == 200) {
                var userData = await GetData(urls.users + "?username=" + this.state.username, false);
                    
                if(userData.code==200){
                    localStorage.setItem("userD", JSON.stringify(userData.message.results[0]));
                    localStorage.setItem("token", tokenRefresh.message.access);
                    
                    history.push('/home');
                    window.location.href = window.location.href;
                }
            } else {
                this.setState({ errorMsg: "Unable to login at this time" });
            }
        } else {
            this.setState({ errorMsg: "Unable to login at this time" });
        }
    }

    render() {
        const {errorMsg } = this.state;

        return (
            <div className="login-page chat-sidebar-container nav-collapsed align-items-center" style={{}}>

                <div className="container align-items-center" style={{ marginTop: '15vh' }}>
                    <main id="content" className="widget-login-container" role="main">

                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-6 col-10">
                                <div style={{ padding: 20 }}>
                                    <center>
                                        <img src="img/logo.png" />
                                    </center>
                                </div>
                                <section className="widget widget-login">
                                    <header className='text-center'>
                                        <h5>Login to your Taussh</h5>
                                    </header>
                                    <hr />
                                    
                                    <form onSubmit={()=>this.login()}className="widget-body">
                                        <p className="widget-login-info text-center">
                                            Login in with your email address and password
                                        </p>
                                        {(errorMsg!="")&&
                                        <div className="col-lg-12">
                                            <div class={"alert alert-danger alert-sm alert-transparent"}>
                                                {errorMsg}
                                            </div>
                                        </div>}
                                        <div className="login-form mt-lg">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Username" onChange={(e)=>this.setState({username:e.target.value})}/>
                                            </div>
                                            <div className="form-group">
                                                <input className="form-control" id="pswd" type="password" placeholder="Password" onChange={(e)=>this.setState({password:e.target.value})}/>
                                            </div>
                                            <br/>
                                            <div className="row">
                                                <div className="col-md-6 col-md-push-6 pt-10">
                                                    <a className="mr-n-lg" href="#">Forgotten your password?</a>
                                                </div>

                                                <div className="col-md-6 col-md-pull-6">
                                                    <div className="btn-toolbar float-right">
                                                        {(this.state.loading==false)?
                                                        <button className="btn btn-inverse btn-md" onClick={()=>this.login()}>Login</button>:
                                                        <Spinner bg="#fff" fill="#f55d12"/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}