import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Form from '../components/EditForm';
import { $ } from 'jquery';
import { formData,GetData,generateDropdownData } from '../common';
import queryString from 'query-string';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Measurement Details",
            sublabel: "Measurement Details",
            breadcrumbs: [{label:"Measurement",href:"/measurements"}, {label:"Measurement Details",href:"/measurementdetail"}],
            endpoint:"measurementdetails/",
            pageLinks:[{label:"Manage Measurements Details",link:"/measurementdetail"}],
            columns:[
                {label:"Name",name:"name",required:true,type:"text"},
                {label:"Measurement Header",name:"header",required:false,type:"select"},
                { label: "Image", name: "image", required: false, type: "file"},
                {label:"Status",name:"is_active",required:false,type:"select",data:[{label:"Active",value:true},{label:"Inactive",value:false}]},
            ],
            payload: {

            },
            id: ""
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        var measurement_header=await GetData("measurementheader/");
        var measurement_header_list=generateDropdownData(measurement_header,"id|type");

        console.log(measurement_header_list);
        var columns=this.state.columns;
        columns[1]['data']=measurement_header_list;
        this.setState({columns:columns});
    }


    onChange = async (event) => {
        var payload = this.state.payload;
        payload[event.target.name] = event.target.value;
        this.setState({ payload: payload });


        this.setState({ payload: payload });
    }



    render() {
        const { breadcrumbs, label, sublabel, columns, pageLinks } = this.state;

        return (
            <>
                <Header />
                <Menu />
                <div class="content-wrap">
                    <main id="content" class="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data => {
                                return <li className="breadcrumb-item"><a href={(data.href != "") ? data.href : "#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <section class="widget">
                            <header>
                                <h5>
                                    <span className="fw-semi-bold">{sublabel}</span>
                                </h5>
                                <div class="widget-controls">
                                    {pageLinks.map(data => {
                                        return <a href={data.link} class="btn btn-primary mt-2 mr-3 text-white py-1 px-2" role="button">{data.label}</a>
                                    })}
                                </div>
                            </header>
                            <div class="widget-body">

                                <div class="row">
                                    <Form data={this.state} id={queryString.parse(this.props.location.search).id} />
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </main>
                </div>
                <div class="loader-wrap hiding hide">
                    <i class="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}