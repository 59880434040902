import React, { Component } from 'react';
import history from '../history';
import Spinner from '../components/Spinner';
import Menu from '../components/Menu';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { $ } from 'jquery';

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            label: "Grid Label",
            sublabel: "Grid Label",
            breadcrumbs: [{label:"Feature Name",href:""}, {label:"Operation",href:""}],
            gridColumns:["id","name","hex_code"],
            endpoint:"/color"
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        
    }

    render() {
        const {breadcrumbs,label,sublabel,loading} = this.state;

        return (
            <>
                <Header />
                <Menu/>
                <div className="content-wrap">
                    <main id="content" className="content" role="main">

                        <ol className="breadcrumb">
                            {breadcrumbs.map(data=>{
                                return <li className="breadcrumb-item"><a href={(data.href!="")?data.href:"#"}><strong>{data.label}</strong></a></li>
                            })}
                        </ol>
                        <h2 className="page-title"><span className="fw-semi-bold">{label}</span></h2>
                        <div className="row">
                            <div className="col-lg-12">
                                <section className="widget">
                                    <header>
                                        <h5>
                                            <span className="fw-semi-bold">{sublabel}</span>
                                        </h5>
                                        <div className="widget-controls">
                                            <a href="#"><i className="la la-cog"></i></a>
                                            <a data-widgster="close" href="#"><i className="la la-remove"></i></a>
                                        </div>
                                    </header>
                                    <div className="widget-body">
                                        {(loading==true)&&<Spinner bg="#fff" fill="#f00"/>}
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="d-none d-md-table-cell">#</th>
                                                    <th>Picture</th>
                                                    <th>Description</th>
                                                    <th className="d-none d-md-table-cell">Info</th>
                                                    <th className="d-none d-md-table-cell">Date</th>
                                                    <th className="d-none d-md-table-cell">Size</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="d-none d-md-table-cell">1</td>
                                                    <td>
                                                        <img className="img-rounded" src="demo/img/pictures/1.jpg" alt="" height="50" />
                                                    </td>
                                                    <td>
                                                        Palo Alto
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <p className="no-margin">
                                                            <small>
                                                                <span className="fw-semi-bold">Type:</span>
                                                                <span className="text-semi-muted">&nbsp; JPEG</span>
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                <span className="fw-semi-bold">Dimensions:</span>
                                                                <span className="text-semi-muted">&nbsp; 200x150</span>
                                                            </small>
                                                        </p>
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        September 14, 2012
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        45.6 KB
                                                    </td>
                                                    <td className="width-150">
                                                        <div className="bg-gray-lighter progress progress-sm">
                                                            <div className="progress-bar progress-sm bg-success js-progress-animate"
                                                                style={{ width: 0 }} data-width="29%"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="d-none d-md-table-cell">2</td>
                                                    <td>
                                                        <img className="img-rounded" src="demo/img/pictures/2.jpg" alt="" height="50" />
                                                    </td>
                                                    <td>
                                                        The Sky
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <p className="no-margin">
                                                            <small>
                                                                <span className="fw-semi-bold">Type:</span>
                                                                <span className="text-semi-muted">&nbsp; PSD</span>
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                <span className="fw-semi-bold">Dimensions:</span>
                                                                <span className="text-semi-muted">&nbsp; 2400x1455</span>
                                                            </small>
                                                        </p>
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        November 14, 2012
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        15.3 MB
                                                    </td>
                                                    <td className="width-150">
                                                        <div className="bg-gray-lighter progress progress-sm">
                                                            <div className="progress-bar progress-sm bg-warning js-progress-animate"
                                                                style={{ width: 0 }} data-width="33%"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="d-none d-md-table-cell">3</td>
                                                    <td>
                                                        <img className="img-rounded" src="demo/img/pictures/3.jpg" alt="" height="50" />
                                                    </td>
                                                    <td>
                                                        Down the road
                                                        <br />
                                                        <span className="badge bg-danger">INFO!</span>
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <p className="no-margin">
                                                            <small>
                                                                <span className="fw-semi-bold">Type:</span>
                                                                <span className="text-semi-muted">&nbsp; JPEG</span>
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                <span className="fw-semi-bold">Dimensions:</span>
                                                                <span className="text-semi-muted">&nbsp; 200x150</span>
                                                            </small>
                                                        </p>
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        September 14, 2012
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        49.0 KB
                                                    </td>
                                                    <td className="width-150">
                                                        <div className="bg-gray-lighter progress progress-sm">
                                                            <div className="progress-bar progress-sm bg-info js-progress-animate"
                                                                style={{ width: 0 }} data-width="38%"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="d-none d-md-table-cell">4</td>
                                                    <td>
                                                        <img className="img-rounded" src="demo/img/pictures/4.jpg" alt="" height="50" />
                                                    </td>
                                                    <td>
                                                        The Edge
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <p className="no-margin">
                                                            <small>
                                                                <span className="fw-semi-bold">Type:</span>
                                                                <span className="text-semi-muted">&nbsp; PNG</span>
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                <span className="fw-semi-bold">Dimensions:</span>
                                                                <span className="text-semi-muted">&nbsp; 210x160</span>
                                                            </small>
                                                        </p>
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        September 15, 2012
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        69.1 KB
                                                    </td>
                                                    <td className="width-150">
                                                        <div className="bg-gray-lighter progress progress-sm">
                                                            <div className="progress-bar progress-sm bg-danger js-progress-animate"
                                                                style={{ width: 0 }} data-width="17%"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="d-none d-md-table-cell">5</td>
                                                    <td>
                                                        <img className="img-rounded" src="demo/img/pictures/11.jpg" alt="" height="50" />
                                                    </td>
                                                    <td>
                                                        Fortress
                                                    </td>
                                                    <td className="d-none d-md-table-cell">
                                                        <p className="no-margin">
                                                            <small>
                                                                <span className="fw-semi-bold">Type:</span>
                                                                <span className="text-semi-muted">&nbsp; JPEG</span>
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                <span className="fw-semi-bold">Dimensions:</span>
                                                                <span className="text-semi-muted">&nbsp; 1452x1320</span>
                                                            </small>
                                                        </p>
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        October 1, 2012
                                                    </td>
                                                    <td className="d-none d-md-table-cell text-semi-muted">
                                                        2.3 MB
                                                    </td>
                                                    <td className="width-150">
                                                        <div className="bg-gray-lighter progress progress-sm">
                                                            <div className="progress-bar progress-sm bg-primary js-progress-animate"
                                                                style={{ width: 0 }} data-width="41%"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                        </div>
                        <Footer />
                    </main>
                </div>
                <div className="loader-wrap hiding hide">
                    <i className="fa fa-circle-o-notch fa-spin-fast"></i>
                </div>
            </>
        );
    }
}